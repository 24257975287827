<template>
     <div>
      <hr/>
        <h3>Please Fill your data! </h3>
<form   @submit.prevent="addForm"       class="cf">
  <div class="half left cf">
    <input ref="name" type="text" id="name" required    name="name" placeholder="Name" for="name"  >
    <input ref="email" type="email" id="email" required  name="email" placeholder="E  mail address" for="email">
    <input ref="phone" type="tel" name="phone"  id="phone"  required  placeholder="Phone Number" for="phone">
  </div>
  <div class="half right cf">
    <textarea ref="message" name="message" type="text"   id="message" placeholder="Message" for="message">
    </textarea>
  </div>  
 <div class="relative fullwidth col-xs-12">
                            <button type="submit" id="submit" name="submit" class="form-btn semibold">Send Message</button> 
                        </div>
                        </form>
 
               </div>
</template>

<script>
import axios from 'axios'

export default {

data(){

         
    return({
           
    })
},

 methods:  {

     addForm   () {
          var mystring="List of Required Items:\n"
          var items=this.$store.state.userCart
          for (var name=0;
                  name<items.length;
                  name=name+1)
        {
          
                  if(items[name].selected) 
                  {
                    
                     
                     mystring= mystring + "Item Number "+name + "\n"
                     mystring= mystring + "Photo ID: " +items[name].ID + "\n"
                     mystring= mystring +"Product Name: "+ items[name].post_title + "\n"
                     mystring= mystring +"Product Category: "+ items[name].parent + "\n"
                     mystring= mystring +"Product Code: "+ items[name].caption + "\n" 
                     mystring= mystring +"Required Quantity: "+ document.getElementById(items[name].ID).value + "\n"
                     mystring= mystring +"Photo : "+ items[name].guid + "\n"

                 


                }

              }



        
  
          let    notify = this.$notify
  var bodyFormData = new FormData();
         bodyFormData.append('your-name', this.$refs.name.value);
         bodyFormData.append('your-email', this.$refs.email.value);
         bodyFormData.append('your-message', this.$refs.message.value +"\n\n\n\n"+ mystring);
          // document.getElementById('message').value);
         bodyFormData.append('your-Phone', this.$refs.phone.value);
console.log(bodyFormData,  this.$refs.email.value)
         
              axios({
  method: "post",
  url: "https://werentegypt.com/wp-json/contact-form-7/v1/contact-forms/10249/feedback",
  data: bodyFormData,
  headers: { "Content-Type": "multipart/form-data" },
})
  .then(function (response) {
//handle success
    console.log(response);

if (response.data.status=="mail_sent")      notify({
                group: 'foo',
                type:'success',
                title: 'E-mail sent',
                text: 'Thank you for your message. It has been sent.',
                 
                duration:3000
                });


         else   notify({
                group: 'foo',
                type:'error',
                title: 'failed to send your Message   ',
                text: 'One or more fields have an error! Please check and try again.',
                 
                duration:3000
                });  
    
  })
  .catch(function (response) {
    //handle error
           console.log(response);

             notify({
                group: 'foo',
                type:'error',
                title: 'failed to send your Message   ',
                text: 'One or more fields have an error! Please check and try again.',
                 
                duration:3000
                });  
  });
return ''
            }

         
        }
    
 
}

</script>

<style lang="scss">
// @import "compass/css3";

@import url(https://fonts.googleapis.com/css?family=Merriweather);
$red: #e74c3c;

*, 
*:before, 
*:after {
  box-sizing: border-box;
}

html, body {
  background: #f1f1f1;
  font-family: 'Merriweather', sans-serif;
  padding: 1em;
}

 h3 {
   text-align: center;
   color: #a8a8a8;
  text-shadow:(1px 1px 0 rgba(white, 1));
      margin-top: 58px;
}

form {
   max-width: 600px;
   text-align: justify;
   margin: 20px auto;
  
  input, textarea {
     border:0; outline:0;
     padding: 1em;
     border-radius:(8px);
     display: block;
     width: 100%;
     margin-top: 1em;
     font-family: 'Merriweather', sans-serif;
     box-shadow:(0 1px 1px rgba(black, 0.1));
     resize: none;
    
    &:focus {
      box-shadow:(0 0px 2px rgba($red, 1) "!important");
    }
  }
  

  textarea {
      height: 126px;
  }
}
.half {
  float: left;
  width: 48%;
  margin-bottom: 1em;
}

.right { width: 50%; }

.left {
     margin-right: 2%; 
}


@media (max-width: 480px) {
  .half {
     width: 100%; 
     float: none;
     margin-bottom: 0; 
  }
}


/* Clearfix */
.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}
.button{
    padding:8px 12px;
    background:#0A5175;
    display: block;
    width:120px;
    margin:10px 0 0px 0;
    border-radius:3px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    text-align:center;
    font-size:0.8em;
    box-shadow: 0px 1px 4px rgba(0,0,0, 0.10);
    -moz-box-shadow: 0px 1px 4px rgba(0,0,0, 0.10);
    -webkit-box-shadow: 0px 1px 4px rgba(0,0,0, 0.10);
  }

  .button:hover{
    background:#8BC3A3;
    color:white;
  }

/* Send Button Styles */

  .form-btn{
    width:180px;
    display: block;
    height: auto;
    padding:15px;
    color:#fff;
    background:#C7DC67;
    border:none;
    border-radius:3px;
    outline: none;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    margin:auto;
    box-shadow: 0px 1px 4px rgba(0,0,0, 0.10);
    -moz-box-shadow: 0px 1px 4px rgba(0,0,0, 0.10);
    -webkit-box-shadow: 0px 1px 4px rgba(0,0,0, 0.10);
  }

  .form-btn:hover{
    background:#111;
    color: white;
    border:none;
  }

  .form-btn:active{
    opacity: 0.9;
  }
 
</style>