import axios from 'axios'
import { unserialize } from 'php-serialize'
  const getStorage=()=>{
    
    try {
        let x = JSON.parse( localStorage.getItem("exc-Gallery") )
    // console.log( x)
    return x
   } catch (error) {
     console.log("Local Storage Reset");
     localStorage.setItem("exc-Gallery",[])
     return[]
   }
}

// page[selectedIndex].selected
// userCart[cartSelectedIndex]

export const storeObj = {
    state: {
        // main pages photos
        page:[
          ]  ,
        viewGallery: false,
        selectedIndex: 0,
        pagination: 1, 
        recordsCount: 0,
        //user cart pages/photos
        userCart: getStorage(),
        cartSelectedIndex:0,

        //view type .. cart or page
        viewType: '',
        increase:'sm',

        isLoading: false,
        cartMsg: "",
        restart: true,
        isSubmnu:false,
        isOrder:false
    
    },
    getters:{
        getStorage( state){
           // return getStorage()
           return state.userCart
        },
        getItems(state){

        
               state.increase = "sm" 
             state.isSubmnu = false
            
            return state.viewType == 'cart'? state.userCart
            :
           state.page
        }
        ,
        viewimage(state){
            let imgIndx =  state.viewType == 'cart'?
             state.cartSelectedIndex : state.selectedIndex
            //  console.log(("............", imgIndx));
               return   state.viewType == 'cart'?
                     state.userCart[imgIndx] :
                     state.page[imgIndx]
        }
    },
    actions: {

        async selectAndAddCart({   commit },{ id, notify} ){
            await  commit("updateViewGallery", {value:false, viewType:"page"})
            await  commit("selectImage",{img:id,
              value: true , notify: null})
              //console.log(this.);
            await  commit("addToMyList", notify)
        },
        // ...
        commitItems: ({ state, commit }, {slug, page}) => {
           let pg = page ? page : 1
           console.log(pg,"--------------------",slug);
          //  this.updateViewGallery(state, {value:false})
          
          if(state.viewType != 'cart'){
                 commit("setCartMsg","Browse our catalogue for lounges, sofas, chairs, accessories, lights and more.")
            }
                commit( "setLoading", true)
                axios.get("https://werentegypt.com/wp-json/exc/galleries/slug="+slug+"/page="+pg).then((response)=>{
                    const arr = []
                    for (let index = 0; index < response.data.results.length; index++) {
                        const element = response.data.results[index];
                        const x = unserialize(element.sizes);
                        arr[index] = response.data.results[index];
                        // if (element.sizes && element.sizes.file)
                          arr[index].guid = `https://werentegypt.com/wp-content/uploads/${x.file}`
                    }
                    // console.log(response.data.results,"yyyyyyyyyyyyyyyy", arr, );

                    commit("updateGalleryPage", arr)
                    commit("setPagination", pg)
                   
                    commit("setRecordsCount", response.data.count[0].count)
                    commit("setIsSubmnu", true)
                    commit("setincrease","sm")
                    commit( "setLoading", false)
                    console.log(state.recordsCount)
                })
             

            
        }
      },


      
    mutations:{
        
        setIsOrder(state, value){
            state.isOrder = value
        },
        setIsSubmnu(state, value){
            state.isSubmnu = value
        },
        setrestart(state, value){
            state.restart = value
        },
        setCartMsg(state, value){
            state.cartMsg = value
        },
        setLoading(state,value){
            state.isLoading = value
        },
        setincrease(state, xx){
             
            state.increase = xx
          
       },
       setPagination(state,value){
            state.pagination = value
       },
       
       setRecordsCount(state,value){
            state.recordsCount = value
       },

        updateGalleryPage(state,value){
            state.page = value
        },
        updateSelectedIndex(state, value){
            state.selectedIndex = value
        },
        updateCartSelectedIndex(state, value){
            state.cartSelectedIndex = value
        },

        updateViewGallery(state, {value, viewType}){ 
            if(value &&  viewType=="cart"){

                state.userCart= localStorage.getItem("exc-Gallery")?
                JSON.parse( localStorage.getItem("exc-Gallery") ) :[]

             }
             if(viewType)
                state.viewType = viewType
                
             state.viewGallery = value
             console.log(state.viewGallery, state.viewType);
        },

        updateViewType(state, value){
            state.viewType = value
        },

        selectImage(state, {img,value, notify}){
            let obj = state.viewType=="cart"?  [...state.userCart]:[...state.page] 
            let stat //= state
         
             obj.find((a, index)=>{
                    if(img==a.ID)
                    {
                        obj[index].selected = value
                        stat = obj[index].selected
                    }
                })
            if(notify)
              if(stat)
                notify({
                    group: 'foo',
                    type:'success',
                    title: 'Item Selected',
                    text: 'Your item have been selected!',
                     
                    duration:1000
                    });
                else
                 notify({
                    group: 'foo',
                    type:'warn',
                    title: 'Item Un-Selected',
                    text: 'Your item have been un-selected!',
                     
                    duration:1000
                    });  

            if(state.viewType=="cart") state.userCart = obj
            else state.page = obj
        }
        ,
        async addToMyList(state, notify){
            let x = state.viewType == 'cart'? state.userCart :state.page
           if(state.viewType !=="cart") state.page[ state.selectedIndex ] .selected = true
           if(notify)
            if(
                x.find(el=>{
                   if(el.selected) { 
                        return true
               
               }})
               
           )
            notify({
                group: 'foo',
                type:'success',
                title: 'Items Added',
                text: 'Your items have been added to cart!',
                 
                duration:3000
                });
            else
             notify({
                group: 'foo',
                type:'error',
                title: 'No Items Selected',
                text: 'Please select items before adding to cart!',
                 
                duration:3000
                });  
               
            let cart = [...state.userCart]
         await   state.page.map(({ID, selected }, index)=>{
               let cc= state.userCart.find((x, dx)=>{
                    if(x.ID == ID ) return dx

                })
                if(!cc && selected)
                {  
                    cart.push( state.page[index] )
                }

            })
         state.userCart = cart
         localStorage.setItem("exc-Gallery",  JSON.stringify(cart))
        }
,
        async removeFromMyList(state, {notify, id}){
            let x =  [...state.userCart]  
            x.splice(id,1)
           state.userCart = x
           if(notify)
            if(
                x.find(el=>{
                   if(!el.selected) { 
                        return true
               
               }})
               
           )
           notify({
            group: 'foo',
            type:'error',
            title: 'No Items Selected',
            text: 'Something went wrong!',
             
            duration:3000
            });  

            else
              notify({
                group: 'foo',
                type:'success',
                title: 'Items Added',
                text: 'Your items have been removed from cart!',
                 
                duration:3000
                });       
               
        //     let cart = [...state.userCart]
        //  await   state.page.map(({ID, selected }, index)=>{
        //        let cc= state.userCart.find((x, dx)=>{
        //             if(x.ID == ID ) return dx

        //         })
        //         if(!cc && selected)
        //         {  
        //             cart.push( state.page[index] )
        //         }

        //     })
        //  state.userCart = cart
         localStorage.setItem("exc-Gallery",  JSON.stringify(state.userCart))
        }
    }
    //   mutations: {
    //     // increment (state) {
    //     //   //state.count++
    //     // }
    //   }
}