import { render, staticRenderFns } from "./Viewer.vue?vue&type=template&id=5b3317d3&scoped=true&"
import script from "./Viewer.vue?vue&type=script&lang=js&"
export * from "./Viewer.vue?vue&type=script&lang=js&"
import style0 from "./Viewer.vue?vue&type=style&index=0&id=5b3317d3&scoped=true&lang=css&"
import style1 from "../../../public/static/css/main.css?vue&type=style&index=1&id=5b3317d3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b3317d3",
  null
  
)

export default component.exports