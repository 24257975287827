<template>
                   
                             <!-- Media-andPhotograph  -->

           <div>
               <div  class="row">                     
                            <div class="col-1" ></div>                        
                            <h5 class="ttl col-10  "  style="margin-top: -31px;     margin-bottom: 24px;" > Media & Photography </h5>
              </div>
              <div class="photo-cont">
               
                        <div  class="row">
                           
               
                                 <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/vintages/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/vintage.jpg');" alt=""/> 
                                             <span >  Vintages Furniture  </span>
                        
                                 </router-link>
                              </div>
                              </div>


                                 <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/vintages-accessories/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('https://werentegypt.com/wp-content/uploads/2022/04/oldradio.jpg');" alt=""/> 
                                             <span >  Vintages Accessories  </span>
                        
                                 </router-link>
                              </div>
                              </div>


                             


                     </div>

                     <div  class="row">
                           
    <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/living-rooms/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/residential-furniture.jpg');" alt=""/> 
                                             <span > Modern Living Rooms & Sofas</span>
                        
                                 </router-link>
                              </div>
                              </div>

                              <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/dining-rooms/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/16b4c479-151f-4aec-896d-040fa9e747d4.jpg');" alt=""/> 
                                             <span >Dining Rooms </span>
                        
                                 </router-link>
                              </div>
                              </div>

                           


                     </div>

                     <div  class="row">
                           

                            <div class=" col-6"> 
                                 <div class="lis" >
                                    <router-link  to="/media-photography/consoles/"  class=""   >
                                       <div  class="menu-bk"  style="background:url('/images/MediaAndPhotograph/media-console.jpg');"  alt=""/> 
                                          <div>  <span  > Consoles  </span> </div>
                        
                                 </router-link>
                              </div>
                              </div>


                        

                              <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/bedrooms/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/bedrooms.jpg');" alt=""/> 
                                             <span >  Bedrooms  </span>
                        
                                 </router-link>
                              </div>
                              </div>

                               


                   
                           

                     </div>
           

                     <div  class="row">

<div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/bathrooms-and-beyond/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/bath_(1).png');" alt=""/> 
                                             <span >  Bathrooms & Beyond </span>
                        
                                 </router-link>
                              </div>
                              </div>


                               

                      <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/kitchens/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/kitchens.jpg');" alt=""/> 
                                             <span >  Kitchens  </span>
                        
                                 </router-link>
                              </div>
                              </div>




                     


                              
                        
                     </div>

                             <div  class="row">

                                  <div class=" col-6"> 
                                 <div class="lis" >
                                    <router-link  to="/media-photography/lightings-media-photography/"  class=""   >
                                       <div  class="menu-bk"  style="background:url('https://werentegypt.com/wp-content/uploads/2022/04/LightingM-icon.jpeg');"  alt=""/> 
                                          <div>  <span  > Lightings  </span> </div>
                        
                                 </router-link>
                              </div>
                              </div>

                        <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/frames/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/frames.jpg');" alt=""/> 
                                             <span >  Frames</span>
                                 </router-link>
                              </div>
                              </div>


                    

               
                              </div>
                       <div  class="row">

                             <div class=" col-6"> 
                                 <div class="lis" >  
                                    <router-link  to="/media-photography/carpets/"  class=""   >
                                       <div  class="menu-bk"  style="background:url('/images/MediaAndPhotograph/rugs-and-flooring.jpg');"  alt=""/> 
                                          <div>  <span  >  Carpets </span> </div>
                        
                                 </router-link>
                              
                              </div>
                              </div>

                           <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/props/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/vases.jpg');" alt=""/> 
                                             <span > Props</span>
                        
                                 </router-link>
                              </div>
                              </div>

                     

             
                     </div>

                        <div  class="row">
                           
                           <div class=" col-6"> 
                                 <div class="lis" >
                                    <router-link  to="/media-photography/exhibition-tables/"  class=""   >
                                       <div  class="menu-bk"  style="background:url('/images/MediaAndPhotograph/tables.jpg');"  alt=""/> 
                                          <div>  <span >  Tables </span> </div>
                        
                                 </router-link>
                              </div>
                              </div>
                         
                           <div class=" col-6"> 
                                 <div class="lis" >
                                    <router-link  to="/media-photography/exhibition-chairs/"  class=""   >
                                       <div  class="menu-bk"  style="background:url('/images/MediaAndPhotograph/tables-chairs.jpg');"  alt=""/> 
                                          <div>  <span  >  Chairs </span> </div>
                        
                                 </router-link>
                              </div>
                              </div>

                     </div>

              <div  class="row">


                          <div class=" col-6"> 
                                 <div class="lis" >
                                    <router-link  to="/media-photography/outdoor-items/"  class=""   >
                                       <div  class="menu-bk"  style="background:url('/images/MediaAndPhotograph/garden.jpg');"  alt=""/> 
                                          <div>  <span  > Outdoor Items </span> </div>
                                 </router-link>
                              </div>
                              </div>

                            <div class=" col-6"> 
                                 <div class="lis" >
                                    <router-link  to="/media-photography/offices/"  class=""   >
                                       <div  class="menu-bk"  style="background:url('/images/MediaAndPhotograph/Office-furniture-e1.png');"  alt=""/> 
                                          <div>  <span  > Office Items </span> </div>
                        
                                 </router-link>
                              </div>
                              </div>



                          
              </div>
             

           <div  class="row">
      
                                 <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/school-related/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/school-related.jpg');" alt=""/> 
                                             <span > School Related  </span>
                        
                                 </router-link>
                              </div>
                              </div>




                           <div class=" col-6"> 
                                 <div class="lis" >
                                    <router-link  to="/media-photography/tvs/"  class=""   >
                                       <div  class="menu-bk"  style="background:url('/images/MediaAndPhotograph/TV-counters.jpg');"  alt=""/> 
                                          <div>  <span  > TVs </span> </div>
                        
                                 </router-link>
                              </div>
                              </div>

                           

                     </div>
                 
                    

           <div  class="row">
                             
                               <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/radios/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/media-radio.jpg');" alt=""/> 
                                             <span > Radios & Telephones  </span>
                        
                                 </router-link>
                              </div>
                              </div>
                             
                             
                                <div class=" col-6"> 
                                 <div class="lis" >
                                    <router-link  to="/media-photography/babies-and-toddlers/"  class=""   >
                                       <div  class="menu-bk"  style="background:url('/images/MediaAndPhotograph/Babies-Kids.jpg');"  alt=""/> 
                                          <div>  <span  > Baby & Toddler Items </span> </div>
                        
                                 </router-link>
                              </div>
                              </div>   
                            

                      
                     </div> 



           <div  class="row">

                  <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/plants-and-gardens/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/media-plants.jpg');" alt=""/> 
                                             <span >  Plants & Gardens  </span>
                        
                                 </router-link>
                              </div>
                              </div> 

                             <div class=" col-6"> 
                                 <div class="lis" >
                                    <router-link  to="/media-photography/travelling-bags/"  class=""   >
                                       <div  class="menu-bk"  style="background:url('/images/MediaAndPhotograph/luggage.jpg');"  alt=""/> 
                                          <div>  <span  > Travelling Bags </span> </div>
                        
                                 </router-link>
                              </div>
                              </div>


                     </div>

<div class="row">
               <div class=" col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/appliances/"  class="fnt"   >
                                 <div class="menu-bk"  style="background:url('/images/MediaAndPhotograph/appliance_(1).jpg');" alt=""/> 
                                             <span >  Appliances </span>
                        
                                 </router-link>
                              </div>
                              </div>

                           <div class="col-6"> 
                                 <div class="lis">
                              <router-link  to="/media-photography/sports/"  class=""   >
                              <div   class="menu-bk"  style="background:url('https://werentegypt.com/wp-content/uploads/2022/08/S-2.jpeg');" alt=""/> 
                                          <div> <span > Sports </span></div>
                        
                                 </router-link>
                              </div>
                           </div>


</div>
   
  </div>
                         </div>

</template>


             
                 





<script>
export default {
 



}

</script>

<style>
.lis{
  list-style: none;
}

.fnt{
  font-size: 15px;

}
a{
  color: black  !important;


}
   body .lis div.menu-bk {
    width: 150px;
    height: 150px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    margin: 0px;
}
  
.evex-st{
   overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}
/* p {
    margin-top: -64px  !important;
    margin-bottom: 1rem;
    text-align: center;
} */
</style>