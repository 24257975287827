import { render, staticRenderFns } from "./Selection.vue?vue&type=template&id=f35b3032&scoped=true&"
import script from "./Selection.vue?vue&type=script&lang=js&"
export * from "./Selection.vue?vue&type=script&lang=js&"
import style0 from "./Selection.vue?vue&type=style&index=0&id=f35b3032&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f35b3032",
  null
  
)

export default component.exports