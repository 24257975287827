<template>
    <header id="header">  
      <img @click="close" src="/close.svg" width="20px" /> 
      <logo-comp/>
        
      
         <h2 style="text-align:center;margin-top: 10px;">         {{title}}</h2>
        <div style="text-align:left"> 
                {{message}}            
            </div>
 
        <!-- <ul class="icons">
            <li><a :href="twitter" class="icon fa-twitter"><span class="label">Twitter</span></a></li>
            <li><a :href="instagram" class="icon fa-instagram"><span class="label">Instagram</span></a></li>
            <li><a :href="github" class="icon fa-github"><span class="label">Github</span></a></li>
            <li><a :href="email" class="icon fa-envelope-o"><span class="label">Email</span></a></li>
        </ul> -->
    </header>
</template>
<style scoped>
#header {
    padding: 1.5em 2.25em 1.75em 2.25em;
}
#header img{
    
        cursor: pointer;
    
}
</style>


<style scoped src="../../../public/static/css/main.css">
 </style>
<script>
import LogoComp from '../../components/Logo-comp.vue';
//import LogoVue from '../../menu/components/Logo.vue';
import {EventBus} from '../event_bus';

export default {
  components: { 
      LogoComp
   },
    name: 'app-header',
    computed:{
        title(){
            return this.$store.state.viewType == 'cart'?'Your Cart': this.$route.meta.title

        },
        message(){
            //console.log( this.$store.state.cartMsg, this.$store.state.viewType,this.$store.state. userCart)
            //let msg
            if(this.$store.state.viewType=="cart")
            {
               
                 if(this.$store.state.userCart.length)
                    this.$store.commit("setCartMsg","Items added to your cart")
                else this.$store.commit("setCartMsg","Cart is empty!")
             }
            
            return this.$store.state.cartMsg

        }
    },
    methods:{
        close(){
              //  console.log(this.$store.state.viewGallery);
            //    this.$store.dispatch("commitItems", {slug: this.$route.meta.slug })

                this.$store.commit("updateViewGallery", {value:false ,viewType:"page"})
           //     console.log(this.$store.state.viewGallery);
        }
    },
    created() {
        EventBus.$on('global_loaded', (obj) => {
            this.header = obj.title;
            // this.text = obj.metafield.tagline.value;
            // this.twitter = obj.metafield.twitter.value;
            // this.instagram = obj.metafield.instagram.value;
            // this.github = obj.metafield.github.value;
            // this.email = 'mailto:' + obj.metafield.email.value;
        });
    },
    data () {
        return {
            text: null,
            twitter: '',
            instagram: '',
            github: '',
            email: '',
            header: ''
        }
    }
}
</script>