<template>
<div>
<div  v-if="!submnu"  >                     
    <svg    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="18" height="18"
     @click="seteventex(true)"> <path fill-rule="evenodd" d="M7.78 12.53a.75.75 0 01-1.06 0L2.47 8.28a.75.75 0 010-1.06l4.25-4.25a.75.75 0 011.06 1.06L4.81 7h7.44a.75.75 0 010 1.5H4.81l2.97 2.97a.75.75 0 010 1.06z">
        </path></svg>
   
  <sub-menu      /> 
 
        
 </div>
    <div v-else>
  <ul>
        <li>
            <a href="/"  class="mit" > Home </a>
        </li>
      
         <li>
             <div>
             <div href=""  @click="seteventex(false)" class="mit" id="furn" >  Collections
     
                 </div > 
                
                </div>
        </li>
        

         <li>
            <a href="/photography-facility/"  class="mit"> Photography Facility </a>
        </li>
         <li>
            <a href="/events-inspiration/" class="mit" > Events Inspiration </a>
        </li>

         <li>
            <a href="/blogs/" class="mitx"> Blogs  </a>
        </li>

         <li>
            <a href="/get-in-touch/" class="mitx"> Get in Touch </a>
        </li>

          <li>
            <a href="/our-story/" class="mitx"> Our Story </a>
        </li>
  </ul>
        
     </div>
</div>
</template>

<script>

import SubMenu from './Sub-Menu'



export default {

    methods:{

        seteventex(x){
        //   this.submnu=x
          this.$store.commit("setIsSubmnu", x)
           this.$store.commit("setincrease","sm")
        }
    },

   computed:{

       submnu(){
           return this.$store.state.isSubmnu
       } ,

   },

    data(){
    
    
    return{
mediaphot:false

        }
    },
    components:{
       
       
        SubMenu

        
    }
}
</script>


<style>
.mit{
    font-weight: 600;
    font-size: 22px;
    color: black;
}
.mitx{
   
    font-size: 20px;
}
li{
    list-style: none;
}
.ttl{
    padding-left: 0% !important;
    vertical-align: top;
    margin-top: -5px;
}
#furn:hover{

text-decoration: black underline  ;
cursor: pointer;

}
</style>