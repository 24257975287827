<template>

<!--
<div>	 <svg xmlns="http://www.w3.org/2000/svg" width="75px" height="75px"  id="Layer_1" enable-background="new 0 0 64 64" viewBox="0 0 64 64">
	
		<path fill="#2b2e63" d="M47.4,55.7c3.1-4.2,3.4-11.6,3.4-14.9v-0.2l0-2.9c0-0.9-0.4-1.8-1-2.5c-0.7-0.7-1.6-1-2.5-1
		c-0.5,0-0.9,0.1-1.3,0.3c-0.3-1.7-1.7-3-3.5-3c-0.8,0-1.4,0.2-2,0.6c-0.6-1.2-1.8-2-3.2-2c-0.6,0-1.1,0.2-1.6,0.4v-9.6
		c0-2-1.6-3.6-3.6-3.6c-2,0-3.6,1.6-3.6,3.6v18l-1.5-3.7c-0.5-1.3-1.8-2.2-3.3-2.2c-0.5,0-0.9,0.1-1.3,0.3c-1.8,0.8-2.7,2.8-2,4.6
		L24.1,50c0.1,0.5,0.9,4,2.8,6.2c-0.4,0.4-0.6,0.8-0.6,1.4V61c0,1.1,0.9,1.9,1.9,1.9h18.5c1.1,0,1.9-0.9,1.9-1.9v-3.5
		C48.7,56.7,48.2,56,47.4,55.7z M26,49.5C26,49.5,26,49.5,26,49.5l-3.9-12.4c-0.3-0.8,0.1-1.8,0.9-2.1c0.8-0.3,1.8,0.1,2.1,0.9
		l3.4,8.2c0.2,0.4,0.6,0.7,1.1,0.6c0.5-0.1,0.8-0.5,0.8-1V20.9c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6v14.3c0,0.5,0.4,1,1,1
		c0.5,0,1-0.4,1-1v-1.6c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6v3.3c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1V35c0-0.9,0.7-1.6,1.6-1.6
		c0.9,0,1.6,0.7,1.6,1.6v3.5c0,0.5,0.4,1,1,1s1-0.4,1-1v-1.7c0.6-0.7,1.8-0.9,2.4-0.2c0.3,0.3,0.5,0.7,0.5,1.2l0,2.9v0.2
		c0.1,7-1.4,12.5-3.9,14.7H29.2C27.3,54.4,26.2,50.8,26,49.5z M46.7,61H28.3v-3.5h18.5V61z"/>
		
		<rect width="18.5" height="3.5" x="28.3" y="57.5" fill="#4ebde5"/><polygon fill="#36a9db" points="46.7 57.5 46.7 61 28.3 61 28.3 57.5 29.3 57.5 29.3 60 45.7 60 45.7 57.5"/><path fill="#ffe5ab" d="M48.9,40.7v0.2c0.1,7-1.4,12.5-3.9,14.7H29.2c-1.9-1.2-3-4.8-3.2-6.1c0,0,0-0.1,0-0.1l-3.9-12.3
		c-0.3-0.8,0.1-1.8,0.9-2.1c0.8-0.3,1.8,0.1,2.1,0.9l3.4,8.2c0.2,0.4,0.6,0.7,1.1,0.6c0.5-0.1,0.8-0.5,0.8-1V20.9
		c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6v14.3c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1v-1.6c0-0.9,0.7-1.6,1.6-1.6
		c0.9,0,1.6,0.7,1.6,1.6v3.3c0,0.5,0.4,1,1,1c0.5,0,1-0.4,1-1V35c0-0.9,0.7-1.6,1.6-1.6c0.9,0,1.6,0.7,1.6,1.6v3.5c0,0.5,0.4,1,1,1
		s1-0.4,1-1v-1.7c0.6-0.7,1.8-0.9,2.4-0.2c0.3,0.3,0.5,0.7,0.5,1.2L48.9,40.7z"/>
		
		<path fill="#f0d39a" d="M25.5 36.9c-.5-.5-1.4-.7-2-.4-.6.3-1.1.9-1.1 1.5l-.3-.9c-.3-.8.1-1.8.9-2.1.8-.3 1.8.1 2.1.9L25.5 36.9zM48.9 40.7v.2c0 .4 0 .7 0 1.1l0-2.1c0-.4-.2-.9-.5-1.2-.7-.7-1.9-.5-2.5.2 0-.1.1-.2.1-.4v-1.7c.6-.7 1.8-.9 2.4-.2.3.3.5.7.5 1.2L48.9 40.7zM44 35v2c0-.9-.7-1.6-1.6-1.6-.8 0-1.4.5-1.6 1.2V35c0-.9.7-1.6 1.6-1.6C43.3 33.4 44 34.1 44 35zM38.8 33.6v2c0-.9-.7-1.6-1.6-1.6-.8 0-1.4.6-1.6 1.3 0 0 0-.1 0-.1v-1.6c0-.9.7-1.6 1.6-1.6C38.1 32 38.8 32.7 38.8 33.6zM33.6 20.9v2c0-.9-.7-1.6-1.6-1.6-.9 0-1.6.7-1.6 1.6v-2c0-.9.7-1.6 1.6-1.6C32.9 19.3 33.6 20 33.6 20.9z"/><path fill="#2b2e63" d="M51.4,1c-7,0-12.6,5.7-12.6,12.6s5.7,12.6,12.6,12.6S64,20.6,64,13.7S58.3,1,51.4,1z M51.4,24.3
		c-5.9,0-10.7-4.8-10.7-10.7S45.5,3,51.4,3c5.9,0,10.7,4.8,10.7,10.7S57.3,24.3,51.4,24.3z"/>
		
		<path @click="select(true)" fill="#51bf9f" d="M51.4,3c-5.9,0-10.7,4.8-10.7,10.7s4.8,10.7,10.7,10.7c5.9,0,10.7-4.8,10.7-10.7S57.3,3,51.4,3z M56.7,11.3
		L50,17.5c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3L46,14.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l2,2l6-5.5
		c0.4-0.4,1-0.3,1.4,0.1C57.1,10.3,57.1,10.9,56.7,11.3z"/>
		
		<path  @click="select(true)"  fill="#2b2e63" d="M56.7 11.3L50 17.5c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3L46 14.7c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2 2 6-5.5c.4-.4 1-.3 1.4.1C57.1 10.3 57.1 10.9 56.7 11.3zM12.6 1C5.7 1 0 6.7 0 13.7s5.7 12.6 12.6 12.6 12.6-5.7 12.6-12.6S19.6 1 12.6 1zM12.6 24.3c-5.9 0-10.7-4.8-10.7-10.7S6.7 3 12.6 3s10.7 4.8 10.7 10.7S18.5 24.3 12.6 24.3z"/>
		
		<path ="select(false)" fill="#f26e61" d="M12.6,3C6.7,3,1.9,7.8,1.9,13.7s4.8,10.7,10.7,10.7s10.7-4.8,10.7-10.7S18.5,3,12.6,3z M16.6,16.3
		c0.4,0.4,0.4,1,0,1.4c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3L12.6,15L10,17.6c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3
		c-0.4-0.4-0.4-1,0-1.4l2.6-2.6L8.6,11c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.6,2.6l2.6-2.6c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4
		L14,13.7L16.6,16.3z"/><path fill="#3da580" d="M51.4,3c-5.9,0-10.7,4.8-10.7,10.7s4.8,10.7,10.7,10.7c5.9,0,10.7-4.8,10.7-10.7S57.3,3,51.4,3z M51.4,22.8
		c-5.1,0-9.2-4.1-9.2-9.2s4.1-9.2,9.2-9.2c5.1,0,9.2,4.1,9.2,9.2S56.4,22.8,51.4,22.8z"/>
		
		<path @click="select(false)" fill="#e15e5a" d="M12.6,3C6.7,3,1.9,7.8,1.9,13.7s4.8,10.7,10.7,10.7s10.7-4.8,10.7-10.7S18.5,3,12.6,3z M12.6,22.8
		c-5.1,0-9.2-4.1-9.2-9.2s4.1-9.2,9.2-9.2c5.1,0,9.2,4.1,9.2,9.2S17.7,22.8,12.6,22.8z"/>
		<path @click="select(false)" fill="#2b2e63" d="M16.6,16.3c0.4,0.4,0.4,1,0,1.4c-0.2,0.2-0.4,0.3-0.7,0.3s-0.5-0.1-0.7-0.3L12.6,15L10,17.6
		c-0.2,0.2-0.4,0.3-0.7,0.3c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l2.6-2.6L8.6,11c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l2.6,2.6
		l2.6-2.6c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L14,13.7L16.6,16.3z"/></svg>
	</div> -->
    <div class="row "> 
	
		
		
 <button  v-if="!selected" @click="select(true)"   > <span>  Select  </span> </button> 
         <button   v-if="selected" @click="select(false)">  <span>  Deselect  </span>   </button>
     </div>
</template>

<script>
export default {
    
computed:{
	selected(){
     let imgIndx = this.$store.state.viewType == 'cart'?this.$store.state.cartSelectedIndex :this.$store.state.selectedIndex
            let imgarray =  this.$store.state.viewType == 'cart'?(this.$store.state.userCart[imgIndx]?this.$store.state.userCart[imgIndx].selected:false) :this.$store.state.page[imgIndx].selected
          return (imgarray )
  }  
	
},

    methods:{
        select(x){
        let imgIndx = this.$store.state.viewType == 'cart'?
            this.$store.state.cartSelectedIndex :
            this.$store.state.selectedIndex

         
            let imgID =  this.$store.state.viewType == 'cart'?
            this.$store.state.userCart[imgIndx].ID :
            this.$store.state.page[imgIndx].ID
			
           this.$store.commit("selectImage",{img: imgID, value:x, notify: this.$notify})

        }
    }
}
</script>
<style scoped>
/* svg{
	transform: translate(-105px, 55px);
	position: absolute;
    bottom: 57px;
    left: 0;
} */


button {
    background: none;
    padding: 0;
    border: 0;
	transform: translate(-164px, 92px);
	/* position: absolute; */
    bottom: 57px;
    left: 0;
}
 span{
	font-weight: bold;
	font-family:inherit ;
	font-size: 15px;
	mix-blend-mode: normal;
    margin: 1px;}
 

</style>