<template>
  <div class="white-bk">
        <!-- <rotate-square2></rotate-square2> -->

    <div class="container-gall" id="main" >
      <app-header></app-header>
      <thumbs></thumbs>
       
      <app-footer></app-footer>
    </div>

    <div v-if="isOrder && isCart"  class="order">
    <Order /> 

    </div>
    <viewer v-else></viewer>
  </div>
</template>

<style scoped src="../../public/static/css/main.css">
 </style>
<style src="../../public/static/css/noscript.css" scoped>

</style>

<style scoped>
    .order{
        width: calc(100% - 22.6rem);
        height: 100vh;
        overflow: scroll;
    }
    .container-gall{
        border: 1px solid #000000;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    .white-bk{

        width: 100%;
        background: white;
        height: 100vh;
         z-index: 500;
    margin: 0;
    left: 0;
    top: 0;
        position: fixed;
    }

</style>

<script>
import AppHeader from './components/AppHeader'
import AppFooter from './components/AppFooter'
import Thumbs from './components/Thumbs'
import Viewer from './components/Viewer.vue'

import Order from '../menu/components/Newpage.vue'

   
export default {
 // name: 'app2',
  components: {
    AppFooter,
    AppHeader,
    Thumbs,
    Viewer,
    Order
    // RotateSquare2,
  },
  computed:{
      isOrder(){return (this.$store.state.isOrder)}
      ,
      isCart(){
            return this.$store.state.viewType == "cart"? true:false
        }
  },

  created() {
    // Cosmic.getObjectType({ bucket }, { type_slug: 'globals' }, (err, res) => {
    //   EventBus.$emit('global_loaded', res.objects.all[0]);
    //   console.log(res.objects.all[0]);
    // });
  },
}
</script>
