import Vue from 'vue'
import Notifications from 'vue-notification'
import App from './App.vue'
// import AppCart from './AppCart.vue'
 
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'es6-promise/auto'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Vuex from 'vuex'
import Router from 'vue-router'
// import galls from  './components/Gallery.vue'

import {storeObj} from './store'

import VueRouter from 'vue-router'
import VueHead from 'vue-head'
 
Vue.use(VueHead)
Vue.use(VueRouter)

Vue.use(Notifications)
Vue.use(Vuex)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Router)


Vue.config.productionTip = false

try {
 console.log( JSON.parse( localStorage.getItem("exc-Gallery") ))
} catch (error) {
  console.log("Local Storage Reset");
  localStorage.setItem("exc-Gallery",[])
}

const store = new Vuex.Store( storeObj )
//const NotFound = { template: '<p>Page not found</p>' }
//const About = { template: '<p>about page</p>' }
let  routes= [
  {
    path: "/events-exhibitions/bars/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Bars', slug:"bars" }
  },
  // {
  //   path: "/events-exhibitions/accessories/",
  //   component:galls,
  //   meta: { title: 'Accessories', slug:"accessories" }
  // },
  {
    path: "/preview/",
    component:async () => await import("./menu/components/Newpage.vue"),
 
  },
  {
    path: "/cart-1/",
    component:async () => await import("./menu/components/cart-cards.vue"),
 
  },
  {
    path: "/Collections/",
    component:async () => await import("./menu/components/furnishing-main.vue"),
 
  },

  {
    path: "/events-exhibitions/accessories/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Accessories', slug:"accessories" }
  },
  {
    path: "/events-exhibitions/centerpieces/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Centerpieces', slug:"centerpieces" }
  },
  {
    path: "/events-exhibitions/chairs/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Chairs & Arm Chairs', slug:"chairs" }
  },   {
    path: "/events-exhibitions/exhibition-furniture/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Exhibition Furniture', slug:"exhibition-furniture" }
  },
  {
    path: "/events-exhibitions/lounges/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Lounges', slug:"lounges" }
  },{
    path: "/events-exhibitions/outdoor-furniture/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Outdoor Furniture', slug:"outdoor-furniture	" }
  },{
    path: "/events-exhibitions/lighting/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Lighting', slug:"lightings" }
  },{
    path: "/events-exhibitions/podiums/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Podiums', slug:"podiums" }
  },{
    path: "/events-exhibitions/rails-stanchions/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Rails & Stanchions', slug:"rails-stanchions" }
  },{
    path: "/events-exhibitions/sofas/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Sofas', slug:"sofas" }
  },
  {
    path: "/events-exhibitions/tables/",
    component:async () => await import("./components/Tabs.vue"),
    meta: { title: 'High Tables', slug:"high-tables" , tabs: [ 
                // {title:'All', slug:"tables" },
                {title:'High Tables', slug:"high-tables" },
                {title:'Meeting Tables', slug:"meeting-tables" },
                {title:'Coffee Tables', slug:"coffee-tables" },
                {title:'Low Tables', slug:"low-tables" },  
              ]
          }
  },
  {
    path: "/events-exhibitions/tables/high-tables/",
    component:async () => await import("./components/Tabs.vue"),
    meta: { title: 'High Tables', slug:"high-tables" , tabs: [ 
                
                // {title:'All', slug:"tables" },
                {title:'High Tables', slug:"high-tables" },
                {title:'Meeting Tables', slug:"meeting-tables" },
                {title:'Coffee Tables', slug:"coffee-tables" },
                {title:'Low Tables', slug:"low-tables" },  
              ]
          }
  },
  {
    path: "/events-exhibitions/tables/meeting-tables/",
    component:async () => await import("./components/Tabs.vue"),
    meta: { title: 'Meeting Tables', slug:"meeting-tables" , tabs: [ 
                
                // {title:'All', slug:"tables" },
                {title:'High Tables', slug:"high-tables" },
                {title:'Meeting Tables', slug:"meeting-tables" },
                {title:'Coffee Tables', slug:"coffee-tables" },
                {title:'Low Tables', slug:"low-tables" },         
              ]
          }
  },
  {
    path: "/events-exhibitions/tables/coffee-tables/",
    component:async () => await import("./components/Tabs.vue"),
    meta: { title: 'Coffee Tables', slug:"coffee-tables" , tabs: [ 
                
                // {title:'All', slug:"tables" },
                {title:'High Tables', slug:"high-tables" },
                {title:'Meeting Tables', slug:"meeting-tables" },
                {title:'Coffee Tables', slug:"coffee-tables" },
                {title:'Low Tables', slug:"low-tables" },         
              ]
          }
  },
  {
    path: "/events-exhibitions/tables/low-tables/",
    component:async () => await import("./components/Tabs.vue"),
    meta: { title: 'Low Tables', slug:"low-tables" , tabs: [ 
                
                // {title:'All', slug:"tables" },
                {title:'High Tables', slug:"high-tables" },
                {title:'Meeting Tables', slug:"meeting-tables" },
                {title:'Coffee Tables', slug:"coffee-tables" },
                {title:'Low Tables', slug:"low-tables" },       
              ]
          }
  },
  {
    path: "/events-exhibitions/sterilization-cabins-2/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Sterilization Cabins', slug:"sterilization-cabin" }
  },
  {
    path: "/events-exhibitions/booths/",
    component:async () => await import("./components/furnishing-exhibition.vue"),
    meta: { title: 'Booths', slug:"booths" }
  },

  /////// Media & Photography//////
  {
    path: "/media-photography/lightings-media-photography/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Lightings', slug:"lightings-media-photography" }
  },
  {
    path: "/media-photography/modern-accessories/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Modern Accessories', slug:"modern-accessories" }
  },
  {
    path: "/media-photography/appliances/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Appliances', slug:"appliance" }
  },
  {
    path: "/media-photography/sports/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Sports', slug:"sports" }
  },
  {
    path: "/media-photography/carpets/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Carpets', slug:"carpets" }
  },{
    path: "/media-photography/dining-rooms/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: ' Dinning Rooms', slug:"dinning-rooms" }
  },
  {
    path: "/media-photography/frames/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Frames', slug:"frames" }
  },{
    path: "/media-photography/exhibition-chairs/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Chairs', slug:"exhibition-chairs" }
  },{
    path: "/media-photography/exhibition-tables/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: ' Tables	', slug:"exhibition-tables" }
  },{
    path: "/media-photography/outdoor-items/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Outdoor Items', slug:"outdoor-items" }
  },{
    path: "/media-photography/props/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Props', slug:"props" }
  },
  {
    path: "/media-photography/travelling-bags/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Travelling Bags', slug:"travelling-bags" }
  },
  {
    path: "/media-photography/tvs/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'TVs', slug:"tvs" }
  },
  {
    path: "/media-photography/living-rooms/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: ' Modern Living Rooms', slug:"living-rooms" }
  },
  {
    path: "/media-photography/kitchens/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Kitchens', slug:"kitchens" }
  },
  {
    path: "/media-photography/offices/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Offices Items', slug:"offices" }
  },
  // {
  //   path: "/media-photography/clocks/",
  //   component:async () => await import("./components/furnishing-media.vue"),
  //   meta: { title: 'Clocks', slug:"clocks" }
  // },
  // {
  //   path: "/media-photography/decorative-elements/",
  //   component:async () => await import("./components/furnishing-media.vue"),
  //   meta: { title: 'Decorative Elements', slug:"decorative-elements" }
  // },
  {
    path: "/media-photography/bedrooms/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Bedrooms', slug:"bedrooms" }
  },{
    path: "/media-photography/babies-and-toddlers/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Baby and Toddler Items', slug:"babies-and-toddlers" }
  },{
    path: "/media-photography/bathrooms-and-beyond/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Bathrooms & Beyond', slug:"bathrooms-and-beyond" }
  },{
    path: "/media-photography/vintages/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Vintages Furniture', slug:"vintages" }
  },{
    path: "/media-photography/vintages-accessories/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Vintages Accessories', slug:"vintages-accessories" }
  },{
    path: "/media-photography/sofas-and-chaise-lounges/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Sofas & Chaise Lounges', slug:"sofas-and-chaise-lounges" }
  },
  // {
  //   path: "/media-photography/md-lightings/",
  //   component:async () => await import("./components/furnishing-media.vue"),
  //   meta: { title: 'Lightings', slug:"md-lightings" }
  // },
  {
    path: "/media-photography/plants-and-gardens/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: ' Plants & Gardens ', slug:"plants-and-gardens" }
  },
  // {
  //   path: "/media-photography/windows/",
  //   component:async () => await import("./components/furnishing-media.vue"),
  //   meta: { title: 'Windows', slug:"windows" }
  // },
  // {
  //   path: "/media-photography/sports/",
  //   component:async () => await import("./components/furnishing-media.vue"),
  //   meta: { title: 'Sports', slug:"sports" }
  // },
  {
    path: "/media-photography/radios/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Radios & Telephones', slug:"radios" }
  },
  // {
  //   path: "/media-photography/telephones/",
  //   component:async () => await import("./components/furnishing-media.vue"),
  //   meta: { title: 'Telephones', slug:"telephones" }
  // },
  // {
  //   path: "/media-photography/music-instruments/",
  //   component:async () => await import("./components/furnishing-media.vue"),
  //   meta: { title: 'Music Instruments', slug:"music-instruments" }
  // },
  {
    path: "/media-photography/school-related/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'School Related', slug:"school-related" }
  },
  // {
  //   path: "/media-photography/hospital-and-clinic/",
  //   component:async () => await import("./components/furnishing-media.vue"),
  //   meta: { title: 'Hospital & Clinic', slug:"hospital-and-clinic" }
  // },
  // {
  //   path: "/media-photography/linens-and-beyond/",
  //   component:async () => await import("./components/furnishing-media.vue"),
  //   meta: { title: 'Linens & Beyond', slug:"linens-and-beyond" }
  // },
  
  {
    path: "/media-photography/consoles/",
    component:async () => await import("./components/furnishing-media.vue"),
    meta: { title: 'Consoles', slug:"consoles" }
  },
 // { path: '/events-exhibitions/bars', component:Gallery  },
  { path: '/events-exhibitions/accessories/', component: App },
  // { path: '/*', component: AppCart },
//   { path: '/events-exhibitions/accessories/', component: App },
  
]
// console.log(import("./components/Gallery.vue"));
const router = new Router({
  mode: 'history',
  routes:routes
})


router.beforeEach((to, from, next) => {
  if(to.meta.title) document.title=to.meta.title
 

// Vue.nextTick( () => {
//     document.title = to.meta.title ? to.meta.title : 'WE RENT';
//   });  console.log(  to.meta.title ? to.meta.title : 'default title'
//     );
 //    console.log( document.getElementById("modal-scrollable-leftmnu"));

   store.dispatch("commitItems", {slug: to.meta.slug} )
    if(document.getElementById("modal-scrollable-leftmnu"))
    document.getElementById("modal-scrollable-leftmnu").click()
  //   console.log(  !routes.find( a => a.path == from.path )  ,localStorage.getItem("exc-trig") );
   

    if( !routes.find( a => a.path == from.path ) && routes.find( a => a.path == to.path )
      &&(!localStorage.getItem("exc-trig") || localStorage.getItem("exc-trig") == "false")
    ){
       // store.commit("setrestart")
        //refresh
       // trig = false
       localStorage.setItem("exc-trig", true)
      //  console.log("Refreshing.........");
        window. location = to.path;
    }
    else
    { localStorage.setItem("exc-trig", false) //store.commit("setrestart")
    // console.log("Refreshing....2222.....", localStorage.getItem("exc-trig"));
   
   }
    next()

})
// new Vue({
//   store,
//   //render: h => h(App),
//   router,
//   data: {
//     currentRoute: window.location.pathname
//   },
//   // computed: {
//   //   ViewComponent () {
//   //     return routes[this.currentRoute] || NotFound
//   //   }
//   // },
//   // render (h) { return h(this.ViewComponent) }
//   render: h => h(AppCart),
// }).$mount('#exc-cart')
new Vue({
  store,
  //render: h => h(App),
  router,
  data: {
    currentRoute: window.location.pathname
  },
  // computed: {
  //   ViewComponent () {
  //     return routes[this.currentRoute] || NotFound
  //   }
  // },
  // render (h) { return h(this.ViewComponent) }
  render: h => h(App),
}).$mount('#exc-gall')