<template>

<div>
      <div class="card-group center "  >  
   
                 <div class="row container" >
                     
                         <div  class="col-6 mg-0 " v-for=" photo in photos"  :key="photo.ID" > 
            <div class="row cart-card" v-if="photo.selected" >            




                 <div class="col-12  ">
                       <div class=" labl " > {{photo.post_title}}    </div>

                 </div>


                   <div class="col-12 adjust "  > 
                    
                       <img class="imgPrv" :src="photo.guid"/>
                      

                      <div class="dp">
                         <div >
                            <label class=" col-12  bold" >Product ID</label>
                            <label class="  col-12" > {{photo.ID}} </label>
                        </div>
                         <div >
                            <label class="bold col-12" >Product Category</label>
                            <label class="  col-12" >  {{photo.parent}} </label>
                        </div>
                         <div >
                            <label class="bold col-12" >Product Code</label>
                            <label class=" col-12" > {{photo.caption}} </label>
                        </div>
                         <div >

                            <label class="bold col-6" >Required Quantity</label>
                            <input  :id="photo.ID" :productid="photo.ID" type="number" class="col-6" > 

                        </div>
                    <!-- <div class="" > <label>   Id: </label> {{photo.ID}} </div>
                    <div class="" > <label>  parent  : </label>    {{photo.parent}} </div>
                    <div class=""> <label>  caption  : </label>   {{photo.caption}}</div>
                    <div class="qty" > <label>   Qty: </label> <input :productid = "photo.ID" type="number"  class=" col-3"/>  </div> -->
               
                     </div> 

                     </div> 
                    </div>
              
          


         </div>
      </div>      
      </div> 

    

     
 

</div>



</template>




<script>
// import ContactForm from './contact-form'

export default {
components:{  },


data() {


            return {
            
                
            }
        },

  methods: {

      showModal() {
       this.isModalVisible = true;
     },
      closeModal() {
       this.isModalVisible = false;
     },
   
     

         
        }
    
  ,
   computed:{
        photos(){
            let x = this.$store.getters.getStorage
            let ret = [...x]
            for (let index =  x.length -1; index >= 0; index--) {
                  if(x[index] && !x[index].selected) ret.splice(index, 1)
                  console.log("kkkkkkkkkkkkkkkkkk", ret);
            }
                  return ret
           
        }
      }
      
      ,



      
}
</script>

<style>

.titl{
    background-image: url("/images/title.svg");
    background-size: 100%;
    height: 208px;
    padding-top: 6%;
    margin-bottom: 30px;
    background-repeat: no-repeat;
}
 
.center{
    justify-content: center;
    padding-top: 10px;
}
.cntr{
    text-align: center;
}

.item{

    margin-left: 146px;
    margin-top: -128px;
    height: 289px;

}
 .cart-card{
    left: 0%;
    right: 11.77%;
    top: 0%;
    bottom: 0%;

    background: #FFFFFF;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    max-width: 100%;
    margin-left: 30px;


} 
 .cvr{
    left: 0%;
    right: 11.77%;
    top: 0%;
    bottom: 0%;

    background: #FFFFFF;



}
.labl{

font-weight: bold;
font-size: 20px;
text-align: center;

color: #000000;
} 
 

.dp{

  display: inline-block;
  margin-left: 35px;
width: calc(100% - 175px);
}
.adjust{
      margin-top: 10px;
 
      margin-bottom: 10px;
 }
.imgPrv{
  width: 130px;
  height:130px;
  vertical-align:top;
}
</style>