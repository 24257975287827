<template>
<div>
   <div v-if="isSubmnu"  class="row">
            <ul   :class="this.$store.state.increase=='xl'? 'col-4':'col-12'"   >
                <li>
                    <div  class="row">                     
                            <div class="col-2" ></div>                        
                            <h5 class="ttl col-10  "  style="margin-top: -31px;" > Collections </h5>
                    </div>
                </li>
                <li class="pd-5">    
                        
                    
                    <div  href="" id="furn-sub" class="smit" @click="setevnex('events')"  >   Events & Exhibitions  </div> 
                </li>
                <li> 
                    <div href=""  id="furn-sub" class="smit"  @click="setevnex('media')"  >   Media & Photography      </div >
                </li>

                <li> 
                    <a  href="/new-arrivals/"  class="smit">    New Arrivals    </a>
                </li>  

            </ul>


           <div class="col-8" style="margin-top: -40px; border-left: 1px solid #f1f1f1;"  >
                    <div   >

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="18" height="18" v-if="eventsex == 'events'"  @click="setevnex('')"><path fill-rule="evenodd" d="M7.78 12.53a.75.75 0 01-1.06 0L2.47 8.28a.75.75 0 010-1.06l4.25-4.25a.75.75 0 011.06 1.06L4.81 7h7.44a.75.75 0 010 1.5H4.81l2.97 2.97a.75.75 0 010 1.06z"></path></svg>
                        <!-- <b-button  > Back</b-button> -->
                        
                                <evants-and-exhibitions v-if="eventsex == 'events'" />
                                
                    </div>
                    <div   >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="18" height="18" v-if="eventsex == 'media'"  @click="setevnex('')"><path fill-rule="evenodd" d="M7.78 12.53a.75.75 0 01-1.06 0L2.47 8.28a.75.75 0 010-1.06l4.25-4.25a.75.75 0 011.06 1.06L4.81 7h7.44a.75.75 0 010 1.5H4.81l2.97 2.97a.75.75 0 010 1.06z"></path></svg>

                                <media-and-photograph  v-if="eventsex == 'media' "/>
                    </div>

           </div>
    </div>

</div>

</template>


<script>
import EvantsAndExhibitions from './Evants-andExhibitions.vue'
import MediaAndPhotograph from './MediaAndPhotograph.vue'

export default {
   methods:{
      setincrease(x){
          if(x == '')
            this.$store.commit("setincrease","sm")
          else this.$store.commit("setincrease","xl")

       },
        setevnex(x){
          this.eventsex=x 
          this.setincrease(x)
          
        },

    },
  computed:{
       isSubmnu(){
           return   !this.$store.state.isSubmnu
       }
  }
      ,data(){


    return{
eventsex:true,
    }
},


components:{
   
EvantsAndExhibitions,
MediaAndPhotograph
}


}
</script>

<style>


.photo-cont{

   height: calc(100vh - 247px);
    overflow: auto;
    overflow-y: auto;
    overflow-x: hidden;
}

/*
 *  STYLE 11
 */

.photo-cont::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	border-radius: 10px;
}

.photo-cont::-webkit-scrollbar
{
	width: 10px;
	background-color: #F5F5F5;
}

.photo-cont::-webkit-scrollbar-thumb
{
	background-color: #9dc236;
	border-radius: 10px;
	background-image: -webkit-linear-gradient(0deg,	 rgba(255, 255, 255, 0.5) 25%,  transparent 25%,  transparent 50%,  rgba(255, 255, 255, 0.5) 50%,  rgba(255, 255, 255, 0.5) 75%,  transparent 75%,  transparent)
}
#furn-sub:hover{

text-decoration: black underline  ;
cursor: pointer;

}
</style>