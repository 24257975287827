<template>

<div>
  <b-button class="btn" @click="minimize" v-b-modal.modal-scrollable-leftmnu>

<!-- <svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 18 14.697">
  <path id="menu_5_" data-name="menu (5)" d="M4,7.1A1.051,1.051,0,0,0,4,5,1.051,1.051,0,0,0,4,7.1Zm0,6.3a1.051,1.051,0,0,0,0-2.1,1.051,1.051,0,0,0,0,2.1Zm1,5.249A1.026,1.026,0,0,1,4,19.7a1.051,1.051,0,0,1,0-2.1A1.026,1.026,0,0,1,5,18.647ZM8,5A1.051,1.051,0,0,0,8,7.1H20A1.051,1.051,0,0,0,20,5ZM7,12.348A1.026,1.026,0,0,1,8,11.3H20a1.051,1.051,0,0,1,0,2.1H8A1.026,1.026,0,0,1,7,12.348ZM8,17.6a1.051,1.051,0,0,0,0,2.1H20a1.051,1.051,0,0,0,0-2.1Z" transform="translate(-3 -5)"/>
</svg> -->

<svg xmlns="http://www.w3.org/2000/svg" width="26" height="30" viewBox="0 0 18 14.697">
  <path id="menu_5_" data-name="menu (5)" d="M4,7.1A1.051,1.051,0,0,0,4,5,1.051,1.051,0,0,0,4,7.1Zm0,6.3a1.051,1.051,0,0,0,0-2.1,1.051,1.051,0,0,0,0,2.1Zm1,5.249A1.026,1.026,0,0,1,4,19.7a1.051,1.051,0,0,1,0-2.1,1.026,1.026,0,0,1,1,1.047ZM8,5A1.051,1.051,0,0,0,8,7.1H20A1.051,1.051,0,0,0,20,5ZM7,12.348A1.026,1.026,0,0,1,8,11.3H20a1.051,1.051,0,0,1,0,2.1H8A1.026,1.026,0,0,1,7,12.348ZM8,17.6a1.051,1.051,0,0,0,0,2.1H20a1.051,1.051,0,0,0,0-2.1Z"
   transform="translate(-2.995 -5)" fill="#9dc236"/>

</svg>




<p style="color: black; font-size: 13px;">Menu</p>

</b-button> 

  <b-modal :size="this.$store.state.increase" style="max-width:200px;" hide-footer class=""  :class=" increase=='sm'&& 'modal-xl' +' leftmnu modal' " id="modal-scrollable-leftmnu" scrollable title="">
<template #modal-title>
     <!-- <img width="150px" src="/images/logo.png" /> -->
   <!-- <logo/> -->
    </template>

    <div style="    line-height: 2.5;" >
    <menu-items v-if="!x"  /><sub-menu     v-else  /> 
   </div>
  
  </b-modal>
</div>

</template>

<script>

import MenuItems from './Menu-Items'
import SubMenu from './Sub-Menu'
// import Logo from './Logo'



export default { 

     methods:{

        backbtn(x){
          this.back=x
        },

        minimize(){
           this.$store.commit('setincrease','sm')
        }
    },

   
   
    data() {
    //  console.log(this.$store.state.increase);

      return {
        modalShow: false ,
           x:false,
           y:true,
           increase:this.$store.state.increase,
      }
    }   , 
  
   components:{
      MenuItems,
      SubMenu,
      // logo
  
      
      
    }


  }
</script>

<style scoped>
.modal-backdrop {
    background-color: transparent;
}

</style>

<style  > 	


body .modal-backdrop {
    opacity: 0.3;
}
.modal-xl{
    max-width: 700px  !important;
}    
body #modal-scrollable{
  margin-left: 0;
}
body .modal-dialog{
    max-height: 100%;
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 142px;

}
body .modal-content{
  height: 100% !important;
  max-height: 100% !important;
  background: #ffffffd9 ;  
}
.modal{
/* max-width: 60%; */
    height: 100%;

}

.btn{
    position: absolute;
    right: 10%;
    padding: 0 !important;
    background: transparent;
    background-color: transparent !important;
    border: none !important;
}

#modal-scrollable{
    margin-left:1.75rem ;
}
.modal-header {
  border-bottom: hidden !important;    
}
.modal-body{
  padding: 0rem 1rem 1rem 1rem !important;
}
</style>

