<template>
    <div id="viewer">
        <div class="inner">
            <div class="nav-next" v-on:click="selectNext()"></div>
            <div class="nav-previous" v-on:click="selectPrev()"></div>
        </div>
        <div class="slide active" >
            <div class="caption">
                <h2>{{ img.post_title }}</h2>
                <div v-html="img.caption"></div>
            </div>
                <div class="">
                
                </div>
           <div class="image"
            v-bind:style='{ backgroundImage: "url(" + img.guid + "?auto=format,compress&w=2000&q=75)", backgroundSize: "cover",  backgroundPosition: "center" }'>
           </div>
        </div> 
    </div>
</template>
<style scoped>
    html{
        overflow-y: hidden;
    }
    #viewer
    {
        background: white;
    position: fixed  !important;

    }
  .selectBtn{
         padding: 2em 2em 0.75em 2em;
        position: absolute;
        bottom: 0;
        right: 0;
        
        z-index: 1;
  }
    #viewer .slide{
        background: white;
    }
    
</style>
<script>
import {EventBus} from '../event_bus';


export default {
     components: {
    //   Selection
      },
    name: 'viewer',
    created() {

        // EventBus.$on('loaded', (obj) => {
        //     this.img = obj;
        //           //  console.log(this.img,"................");

        // });
    },
    data () {

        // let imgIndx = this.$store.state.viewType == 'cart'?
        //     this.$store.state.cartSelectedIndex :
        //     this.$store.state.selectedIndex

        return {
            
        }
    },
    computed:{


            img(){
                       console.log( this.$store.getters.viewimage);
               // this.img = image
                return  this.$store.getters.viewimage||{}
                
            }
    }
    ,
    methods: {
        selectNext() {
               let imgIndx = this.$store.state.viewType == 'cart'?
            this.$store.state.cartSelectedIndex :
            this.$store.state.selectedIndex
            EventBus.$emit('move', 1);
            console.log(
                this.$store.state.userCart[imgIndx],
                this.$store.state.page[imgIndx] , imgIndx

            );  
        },
        selectPrev() {
            EventBus.$emit('move', -1);
        }
    }
}
</script>

<style scoped src="../../../public/static/css/main.css">
 </style>