<template>
    <div>
        <div class="cart-icon"  @click="viewCart">
          <img id=""   src="/icons/cart3.svg" width="30px" />
            <!-- <App v-if="viewGall" /> -->
          <p style="">Cart</p>
        </div>

    </div>
</template>

<script>
 


  export default {
    data(){

    return {}

    },
    methods:{
      viewCart(){
        this.$store.commit("updateViewGallery",{value:true, viewType:"cart"})
    
      }
    }
  }
 
</script>
