<template>
 <div>
 

   <div id="appx">
     <!-- <router-link class="nav-link" to="/events-exhibitions/accessories/">
 
 
          </router-link>-->
     <!-- <router-view  name="AppCart">
       <App v-if="viewGall" />

     </router-view> -->

    
      <notifications position="bottom left" group="foo" />
       <div style="float:right">
       <Menu />
      <cartMenu/>

    </div>  <Gallery v-if="viewGall" />
     <router-view  >

     </router-view> 


 
  </div>  
 </div>
</template>
 
 
    


<script>
 
// import App from './gallery/App.vue'
import Menu from './menu/App.vue'
import Gallery from './gallery/App'
import cartMenu from './menu/components/cart-menu'

  export default {
    data(){
      // console.log(this.$store.state.viewGallery);
    return {}
   //  return{ viewGall: this.$store.state.viewGallery}
    },
    computed:{
      viewGall(){
        return  this.$store.state.viewGallery
      }
    },
    methods:{
      viewCart(){
        this.$store.commit("updateViewGallery",{value:true, viewType:"cart"})
        // console.log(this.$store.state);
      }
    }
    ,
     components: {
        // eventsIcons,
        // furnishingIcons,
        // category,
          
          Menu, Gallery, cartMenu
      },
    name: 'app'
  }
 
</script>

<!-- CSS libraries -->
<style>

    #carticon{
      /* position: absolute; */
      float: right;
    }
    .cartName{
      float: right;
    }

</style>

<!-- Global CSS -->
<style>
  code {
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
    font-size: 0.9em;
    white-space: pre-wrap;
    color: #2c3e50;
  }

  code::before, code::after {
    content: '`';
  }
</style>

<!-- Scoped component css -->
<!-- It only affect current component -->
<style scoped>
  #app {
    text-align: center;
  }

  #app h1 {
    color: #2c3e50;
    font-weight: 300;
    margin: 0;
  }

  .banner {
    height: 200px;
    background-color: #f6f6f6;
    padding: 50px 10px;
  }

  .bottom {
    padding: 80px 10px;
    font-size: 24px;
    font-weight: 300;
  }

  .fade {
    font-size: 14px;
  }

  .logo {
    animation: spin 4s 1s infinite linear
  }

  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
</style>


    
<!--  
  <div class="banner">

     <eventsIcons/>
  </div>  

  <div class="banner">
    <furnishingIcons/>

  </div>
  <div class="bottom">
    
      <span class="fade">
         
      </span> 
    </div>  -->
    
 

<style>
    .min-u{
        margin-top: 60px  !important;
        max-width: 350px;
        top: 0;
        position: fixed;

        padding-top: 50px;
        right: 0;
        
        border: 1px solid #000000;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    .min-u .photo-cont{
        height: calc(100vh - 200px);
        padding: 15px;
    }
</style>

<!-- CSS libraries -->

<style>
    .min-u{
        margin-top: 60px  !important;
        max-width: 350px;
        top: 0;
        position: fixed;

        padding-top: 50px;
        right: 0;
        
        border: 1px solid #000000;
        box-sizing: border-box;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    }
    .min-u .photo-cont{
        height: calc(100vh - 200px);
        padding: 15px;
    }
</style>
 
<style>

    #carticon{
      /* position: absolute; */
      float: right;
    }
  .vue-notification-group{
    z-index: 11000  !important;
  }
  svg{
    cursor: pointer;
  }
</style>

<!-- Global CSS -->
<style>
  code {
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
    font-size: 0.9em;
    white-space: pre-wrap;
    color: #2c3e50;
  }

  code::before, code::after {
    content: '`';
  }
</style>

<!-- Scoped component css -->
<!-- It only affect current component -->
<style scoped>
  #app {
    text-align: center;
  }

  #app h1 {
    color: #2c3e50;
    font-weight: 300;
    margin: 0;
  }

  .banner {
    height: 200px;
    background-color: #f6f6f6;
    padding: 50px 10px;
  }

  .bottom {
    padding: 80px 10px;
    font-size: 24px;
    font-weight: 300;
  }

  .fade {
    font-size: 14px;
  }

  .logo {
    animation: spin 4s 1s infinite linear
  }

  @keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
  }
</style>
