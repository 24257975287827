<template>
    <footer id="footer" style=" ">
        <!-- <div v-html="text"></div> -->
      <div  ><Selection/></div>
      <div class="row">
   <button v-if="!isCart" @click="commitAdd"  id="ad-btn"><span class="txt" > Add to Cart </span></button></div>
 

 <div class="row">      
        <div v-if="!isCart" class="col-12 btns">
              <!-- <span>+</span> -->
                <!-- <img  @click="commitAdd" src="/icons/add-cart.svg" width="40px"/> -->
                <img  @click="commitAdd" src="/icons/cart2.svg" width="50px"/>
                  
        </div>

        <!-- <div v-if="isCart==true" class="col-12 btns">
            <router-link to="/Collections/" class="" > <div   class="col-12 btns"> Continue Shopping</div></router-link> 
        </div>     -->
<!-- {{isOrderView? 'Back': 'Order Now' }} -->

         <div v-if="isCart" class="col-12 btns">
             
             <router-link  to="/collections" v-if="!CartItemsCount" id="order" > 
              Continue Shopping  </router-link>
 
            <button v-else id="order" @click="toggleView"> {{isOrderView? 'Back': 'Order Now' }}</button>
 
        </div>
 </div>
    </footer>
</template>

<style scoped>
    #order{
        /* color: white; */
/* Group 18 */

  width: 256px;
  height: 45px;
  left: 1621px;
  top: 980px;



/* Rectangle 24 */

   width: 256px;
   height: 45px;
   left: 1621px;
   top: 980px;
   background: #C7DC67;
   border-radius: 10px;


/* Proceed to check out */

   width: 218px;
   height: 22px;
   left: 1640px;
   top: 991px;
   font-weight: bold;
   font-size: 20px;
   line-height: 1px;
   text-align: center;
   color: #FFFFFF;
  /* mix-blend-mode: normal; */

    }
 
    img{
        cursor: pointer;
    }
    .btns{
        text-align: left;
    }
    #footer{
        padding:10px;
         position: fixed;
         bottom: 10px;
         font-size:12px;
        min-width:140px ;
         background: white;
         padding: 9px;
         z-index: 19;

    }
    #ad-btn{
        background: #C7DC67 ;
        height: 27px;
        width: 70px;
        border-radius: 10px;
        margin: 4px;
        margin-left: -10px;
        line-height: 1px;

    
    }
    .txt{

     margin: -15px;
     font-weight: bold;
     font-size: 11px;
     color: #FFFFFF;
     width: 51px;
     height: 20px;
    }
</style>

<style scoped src="../../../public/static/css/main.css">
 </style>
<script>
 import Selection from '../components/Selection.vue'

export default {
     components: {
      Selection
      },

    name: 'app-footer',
    created() {
        this.$router.afterEach(()=>{
               this.$store.commit("updateViewGallery", {value:false, viewType:"page"})
        })
      //  this.$notify('Hello user!')

      // console.log(  JSON.parse( localStorage.getItem("exc-Gallery") ));
    },
 
    methods:{
       async commitAdd(){
           await this.$store.commit("addToMyList", this.$notify)
          },
        toggleView(){
           this.$store.commit("setIsOrder", !this.$store.state.isOrder)
      },
  
        
 
    },
    computed:{
        isOrderView(){
            return this.$store.state.isOrder
        },
        isCart(){
            return this.$store.state.viewType == "cart"? true:false
        },
        CartItemsCount(){
            return this.$store.state.userCart.length
        }
          ,  
        isShopping(){
         if(this.$store.state.viewType=="cart")
            {
               
                 if(this.$store.state.userCart.length)
                    this.$store.commit("setCartMsg","Order Now")
                else this.$store.commit("setCartMsg","Continue Shopping")
             }
            
            return this.$store.state.cartMsg
          }
        
          
},
    data () {
        return {
            text: null
        }
    }
}
</script>
