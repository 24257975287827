<template>
    <div >
                     <div  class="row">                     
                            <div class="col-1" ></div>                        
                            <h5 class="ttl col-10  "  style="margin-top: -31px;     margin-bottom: 24px;" > Events & Exhibitions </h5>
                    </div>
        <!-- Events and Exhibitions -->
   
                <div class="photo-cont">

                        <div  class="row">
                           

                             <div class="col-6"> 
                                 <div class="lis" >
                              <router-link  to="/events-exhibitions/lounges/"  class=""   >
                              <div   class="menu-bk"  style="background:url('/images/EvantsAndExhibitions/Marly-Beige-Lounge.webp');" alt=""/> 
                                          <div>  <span >  Lounges </span></div>
                        
                                 </router-link>
                              </div>
                              </div>

                             
                                      <div class="col-6"> 
                                 <div class="lis">
                              <router-link  to="/events-exhibitions/sofas/"  class=""   >
                              <div   class="menu-bk extr  "  style="background:url('/images/EvantsAndExhibitions/Viola-Vip-Sofa.webp');" alt=""/> 
                                          <div> <span > Sofas </span></div>
                        
                                 </router-link>
                              </div>
                              </div>
                         
                           


                     </div>

                        <div  class="row">
                         
                     <div class="col-6"> 
                                 <div class="lis" >
                              <router-link  to="/events-exhibitions/chairs/"  class=""   >
                              <div  class="menu-bk"  style="background:url('https://werentegypt.com/wp-content/uploads/2022/03/WhatsApp-Image-2022-03-16-at-12.00.16-PM.jpeg');" alt=""/> 
                                          <div>  <span >  Chairs & Arm Chairs </span></div>
                        
                                 </router-link>
                              </div>
                              </div>

                            <div class="col-6"> 
                                 <div class="lis" >
                              <router-link  to="/events-exhibitions/tables/"  class=""   >
                              <div   class="menu-bk"  style="background:url('/images/EvantsAndExhibitions/tab1-1024x683.jpg');" alt=""/>
                                          <div>  <span >  Tables </span></div>
                        
                                 </router-link>
                              </div>
                              </div>


                     </div>


                        <div  class="row">
                           

                                         <div class="col-6"> 
                                 <div class="lis" >
                              <router-link  to="/events-exhibitions/lighting/"  class=""   >
                                 <div  class="menu-bk"  style="background:url('/images/EvantsAndExhibitions/Crystal spikes chandeliers.webp');" alt=""/> 
                                          <div>  <span >  Lightings </span></div>
                        
                                 </router-link>
                              </div>
                              </div>




                           
                           <div class="col-6"> 
                                 <div class="lis" >
                                    <router-link  to="/events-exhibitions/bars/"  class=""   >
                                       <div  class="menu-bk"  style="background:url('/images/EvantsAndExhibitions/18664304_276888132773417_6123079013532064130_n.jpg');"  alt=""/> 
                                          <div>  <span  >  Bars </span> </div>
                        
                                 </router-link>
                              </div>
                              </div>

                     </div>

                         <div  class="row">
                               <div class="col-6"> 

                               
                                 <div class="lis">
                              <router-link to="/events-exhibitions/accessories/"    >
                                 <div class="menu-bk"  style="background:url('/images/EvantsAndExhibitions/Fire-Bowl-with-Stand-x-826x1024.jpg');" alt=""/> 
                                             <span >  Accessories </span>
                        
                              </router-link>
                              </div>
                              </div>
                     <div class="col-6"> 
                        <div class="lis">
                              <router-link  to="/events-exhibitions/centerpieces/"  class=""   >
                                 <div  class="menu-bk"  style="background:url('/images/EvantsAndExhibitions/40551407_510159252779636_1421953089182629888_n.jpg');"  alt=""/> 
                                             <span >  Centerpieces </span>
                        
                                 </router-link>
                              </div>
                              </div>
                     </div>


                     <div  class="row">
                           
                              <div class="col-6"> 
                                 <div class="lis">
                              <router-link  to="/events-exhibitions/outdoor-furniture/"  class=""   >
                                 <div   class="menu-bk"  style="background:url('/images/EvantsAndExhibitions/21105506_320851598377070_6662633680435357621_n.jpg');" alt=""/> 
                                          <div> <span > Outdoor Furniture </span></div>
                        
                                 </router-link>
                              </div>
                              </div>
                           

                              <div class="col-6"> 
                                 <div class="lis">
                              <router-link  to="/events-exhibitions/exhibition-furniture/"  class=""   >
                                    <div   class="menu-bk"  style="background:url('https://werentegypt.com/wp-content/uploads/crystal-asfour.jpg');" alt=""/> 
                                             <span >  Exhibition Furniture </span>
                        
                                 </router-link>
                              </div>
                              </div>


                     </div>


                           <div  class="row">
                             <div class="col-6"> 
                                 <div class="lis" >
                              <router-link  to="/events-exhibitions/rails-stanchions/"   class=""   >
                                 <div   class="menu-bk"  style="background:url('/images/EvantsAndExhibitions/zzzz450.jpg');" alt=""/> 
                                          <div>  <span >  Rails & Stanchions </span></div>
                        
                                 </router-link>
                              </div>
                              </div>
                              <div class="col-6"> 
                                 <div class="lis">
                              <router-link  to="/events-exhibitions/podiums/"  class=""   >
                                 <div  class="menu-bk"  style="background:url('/images/EvantsAndExhibitions/ede80aad-3a52-4b1e-b216-a3e50f31f68e-768x1024.jpg');" alt=""/> 
                                          <div> <span > Podiums </span></div>
                        
                                 </router-link>
                              </div>
                              </div>
                    
                               </div>

                   
                        <div  class="row">
                           
                              <div class="col-6"> 
                                 <div class="lis">
                              <router-link  to="/events-exhibitions/sterilization-cabins-2/"  class=""   >
                              <div   class="menu-bk"  style="background:url('/images/EvantsAndExhibitions/sterilization-cabin-2000-instead-of-3000-rental-daily.jpg');" alt=""/> 
                                          <div> <span > Sterilization Cabin </span></div>
                        
                                 </router-link>
                              </div>
                              </div>
                        
                          <div class="col-6"> 
                                 <div class="lis">
                              <router-link  to="/events-exhibitions/booths/"  class=""   >
                              <div   class="menu-bk"  style="background:url('https://werentegypt.com/wp-content/uploads/2022/04/Booth-icon.jpeg');" alt=""/> 
                                          <div> <span > Booth Rental </span></div>
                        
                                 </router-link>
                              </div>
                           </div>
                        </div>

                        <!-- <div  class="row">
                           
                              <div class="col-6"> 
                                 <div class="lis">
                              <router-link  to="/events-exhibitions/Kiosks Rental/"  class=""   >
                              <div   class="menu-bk"  style="background:url('/images/EvantsAndExhibitions/sterilization-cabin-2000-instead-of-3000-rental-daily.jpg');" alt=""/> 
                                          <div> <span >  Kiosks Rental </span></div>
                        
                                 </router-link>
                              </div>
                              </div>
                        
                         
                        </div> -->
             </div>
    </div>
</template>


<script>
export default {
   
   methods:{
      Fn(){
      //  alert("this message")
      }
   }
   , 
}
</script>

<style >
.lis{
  list-style: none;
}



/* a{
  color: black  !important;


} */
/* body .lis div.menu-bk {
    width: 150px;
    height: 150px;
    background-size: contain !important;
    background-repeat: no-repeat !important;


    } */

    body .lis div.menu-bk {
    width: 150px;
    height: 150px;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    margin: 0px;
}
  
.evex-st{
   overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}
 .lis span{
   font-size: 12px !important;
   word-break: break-all;
   white-space: nowrap  !important;

}


</style>