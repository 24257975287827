<template>
 
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <Menu />
    <!-- <Items/> -->
 
</template>

<script>
import Menu from './components/Menu.vue'
export default {
  name: 'App',
  components: {
    Menu,
  
    
    
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
     /* position: fixed;
    direction: rtl;
    z-index: 999999999;
    margin: 0;
    top: 51px;
    right: 10%; */
}
.modal-backdrop {
    background-color: transparent !important;
}
ol, ul {
    margin: 0 0 0 0;

}
</style>
