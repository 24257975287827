<template>
    <section  v-if="iscart"  id="thumbnails">

        <article  v-for="(item , index) in items" :key="index" v-bind:class="{ 'active': activeIndex == index }">
            <a class="thumbnail" v-on:click="selectImage(item, index)">
                               

            <div class="form-check" >
                
            <input @click="checked"  :item="item.ID" :ref="item.ID"
            class="form-check-input" type="checkbox" 
            :checked=" item.selected"
            value="" :id="'flexCheckDefault'+item.ID" />


            <label class="form-check-label" :for="'flexCheckDefault'+item.ID">
                
            </label>

<svg class="delete-ico"  v-if="isCartView" @click="removeItem(index)"  xmlns="http://www.w3.org/2000/svg" width="13.999" height="15.998" viewBox="0 0 13.999 15.998">
  <path id="solid_trash" data-name="solid trash" d="M13.5,1H9.749L9.455.415A.75.75,0,0,0,8.784,0H5.212a.741.741,0,0,0-.669.416L4.25,1H.5a.5.5,0,0,0-.5.5v1A.5.5,0,0,0,.5,3h13a.5.5,0,0,0,.5-.5v-1A.5.5,0,0,0,13.5,1ZM1.662,14.592A1.5,1.5,0,0,0,3.159,16h7.68a1.5,1.5,0,0,0,1.5-1.406L13,4H1Z" transform="translate(0 0)"/>
</svg>
            </div>     


              <img v-bind:src="decorateImgixUrl(item.guid)" alt="" />
            </a>
            <h2>{{ item.post_title }}</h2>
            <div >{{item.caption}}</div>
        </article>
    </section>
</template>

<style scoped src="../../../public/static/css/main.css">
 </style>
<style scoped>
.delete-ico{
   margin: -30px 9px 0px -3px;
}
article{
    padding: 2px;
}
#thumbnails{
    margin-bottom: 100px;

}
.form-check{

    height: 0;
}
.form-check-input{

    border: none;
}

.form-check-label {
    /* background: blue; fallback */
    /* animation: switchColor 1s;
      animation-iteration-count: infinite;
animation-duration: 4s; */
     border-radius: 5px;
    margin: 10px !important;
    background: white;
    font-size: 0.6rem !important;
    padding: 0 !important;
    border: 2px solid #79b554;
    width: 19px;
    height: 18px;
}

 



</style>
<script>
// import Cosmic from 'cosmicjs';
// import * as Config from '../config';
import {EventBus} from '../event_bus';

// const bucket = { slug: Config.bucket };

export default {
    name: 'thumbs',
    created() {

        // Cosmic.getObjectType({ bucket }, { type_slug: 'photos' }, (err, res) => {
        //     this.items = res.objects.all;
        //     EventBus.$emit('loaded', this.items[0]);
        // });
         let imgIndx = this.$store.state.viewType == 'cart'?
            this.$store.state.cartSelectedIndex :
            this.$store.state.selectedIndex
         EventBus.$emit('loaded',
         this.$store.state.viewType == 'cart'?
            this.$store.state.userCart[imgIndx] :
            this.$store.state.page[imgIndx]
         );
        EventBus.$on('move', (dir) => {

            // console.log(dir,"---------------------------------",this.activeIndex );
            this.activeIndex = parseInt(this.activeIndex) + dir;
            if (dir > 0 && this.activeIndex >= this.items.length) {
                this.activeIndex = 0;
            }
            if (dir < 0 && this.activeIndex < 0) {
                this.activeIndex = this.items.length - 1;
            }
            if(this.$store.state.viewType=="cart")
               this.$store.commit( "updateCartSelectedIndex",this.activeIndex  )
            else
                this.$store.commit( "updateSelectedIndex",this.activeIndex  )
            EventBus.$emit('loaded', this.items[this.activeIndex]);
        });
    },
    data () {
           let imgIndx = this.$store.state.viewType == 'cart'?
            this.$store.state.cartSelectedIndex :
            this.$store.state.selectedIndex
        return {
       
            activeIndex: imgIndx,
            imgs:this.items,
            iscart:this.$store.state.viewType == 'cart'
        }
    },
    mounted (){
        this.selectImage(this.imgs[this.activeIndex], this.activeIndex)
  },
  computed:{
        items(){  
            //console.log(this.$store.getters.getItems,"====================");
              return this.$store.getters.getItems
                
        },
        
        isCartView()
        {
         return   this.$store.state.viewType=="cart"?true:false
    }
  },

 

    methods: {
        removeItem(index){
            this.$store.commit("removeFromMyList", {notify:this.$notify, id:index })
        },
        checked(e){
           // console.log(e, e.target.checked,  this.$store.state.page);
            this.$store.commit("selectImage",{img: e.target.getAttribute("item"),
            value:  e.target.checked, notify: this.$notify})
           // console.log(e, e.checked, this.$refs, this.$store.state.page);
        },
        selectImage (itm, index) {
            EventBus.$emit('loaded', itm);
            this.activeIndex = index;
            if(this.$store.state.viewType=="cart")
               this.$store.commit( "updateCartSelectedIndex",this.activeIndex  )
            else
                this.$store.commit( "updateSelectedIndex",this.activeIndex  )
        },
        decorateImgixUrl (imgixUrl) {
            return imgixUrl + '?auto=format,compress&w=200&dpr=2';
        }    
    }
}
</script>