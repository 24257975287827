<template>
<div  style="
    margin-right: 90px;
    text-align: left;
    margin-left: -34px;
    margin-top: -69px;">
        <img width="150px" src="../images/logo-1.png" />


           <lottie-player
                  class="inner-column"
                  src="/animation_kn7x3yyu.json"
                  mode="bounce"
                  background="transparent"
                  speed="1"
                  style="   width: 120px;   margin-top: -60px;    margin-left: 16px;"

                  loop
                  autoplay
                ></lottie-player>
</div>

</template>
 
<script>


export default {

    components:{
        
    },
    data: () => ({

    }),
     mounted() {
      let recaptchaScript = document.createElement('script')
      recaptchaScript.setAttribute('src', "https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js")
      document.head.appendChild(recaptchaScript)
    },
   
  
};
</script>