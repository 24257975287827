<template>
<div>
    <h1 class="cntr titl">Your Cart</h1>

<div class="swip-view ">
   <span> View As </span>
<div  class="" @click="setIsGrid(true)">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="15.9106" y="15.9106" width="16.0894" height="16.0894" fill="white"/>
    <rect y="15.9106" width="15.9106" height="16.0894" fill="#C7DC67"/>
    <rect width="15.9106" height="15.9106" fill="white"/>
    <rect x="15.9106" width="16.0894" height="15.9106" fill="#C7DC67"/>
    </svg>

</div>
<div  class="" @click="setIsGrid(false)">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.000213623" width="32.0001" height="6.97747" fill="#C7DC67"/>
      <rect y="25.0225" width="32.0001" height="6.97747" fill="#C7DC67"/>
      <rect y="12.5112" width="32.0001" height="6.97747" fill="#C7DC67"/>
      </svg>
    
</div>
</div>

 <div class="container" >
<Cards v-if="isGrid" />
<div v-else>

<table class="card table"  >
  <tr>
     <td  class="bold">
   
     Product Photo
    </td>
     <td  class="bold">
   
    Product ID
    </td>
     <td  class="bold">
   
   Product Name
    </td>
     <td  class="bold">
 
 Product Code
    </td>
     <td  class="bold">
 Product Category
    </td>
     <td  class="bold">
      <label>  </label>
      Required Quantity
    </td>
   </tr>
</table>
<table   v-for=" photo in photos"  :key="photo.ID" class="card  table"  >
  <tr v-if="photo.selected" >
 
    <td>
    <img style="width:80px" :src="photo.guid"/>
    
    </td>
     <td>
     {{photo.ID}}

    </td>
     <td>
     {{photo.post_title}}

    </td>
     <td>
     {{photo.parent}}

    </td>
     <td>
      {{photo.caption}}

    </td>
    <td>
      <label> Qty </label>
        <input  :id="photo.ID" type="number" />
    </td>
  </tr>
</table>
</div>
</div>

 

     <div>
           <ContactForm/>
        </div>
 


 

</div>


</template>



<script>
import ContactForm from './contact-form'
import Cards from './cart-cards.vue'
export default {
components:{
   ContactForm,
   Cards
}
, data(){
    return({
        isGrid: true
    })
}
  ,
  methods:{
    setIsGrid( val ){
        this.isGrid = val
      },
  }
  ,
   computed:{
      
        photos(){
            let x = this.$store.getters.getStorage
         
                  return x
           
        }
      }
      
      ,



      
}
</script>


<style>
 .bold{
    font-weight: bold;
    margin: -12px;
 }
.swip-view div {
    margin: 7px;
}
.swip-view {
    display: flex;
    justify-content: flex-end;
    margin: 18px;
    align-items: center;
}
.card.table{
  display: table;
    flex-direction: column;
    width: 100%;
    table-layout: fixed;
    margin: 0;
}
.card.table td input{
    width: 100%;
}
/* .label{
   color: #94aab0;
  margin-bottom: 10px;
   display: block; color: #2b3e51;
} */
.inp{
  padding-top: 10px;
}
table, th, td {
  border: 1px solid black;
  padding: 1em 2em 1em 2em;
}
.cart-card{
    width: 100%;
  height: calc(100% - 20px);  
  padding-top:15px ;
    margin-bottom:15px ;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 8px;
}
.cvr{
  left: 0%;
right: 11.77%;
top: 0%;
bottom: 0%;

background: #FFFFFF;


}
.labl{

font-weight: bold;
font-size: 20px;
line-height: 23px;
text-align: center;

color: #000000;
}
/* .item{
  left: 26.22%;
right: 46%;
top: 31.74%;
bottom: 58.26%;
} */

</style>